@font-face {
  font-family: 'Inter SemiBold';
  src: url('../../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('../../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Extra Bold';
  src: url('../../assets/fonts/Gilroy-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url('../../assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


// TODO Надо разложить эти стили по правильным файлам  

.search-box {
  .search-input {
    padding-right: 1rem !important;
  }
}

.nav-link {
  font-family: 'Inter Bold', sans-serif;
  color: #8A94AD !important;
}

.nav-link.active,
.show>.nav-link {
  color: #31374A !important;
  border-bottom-color: currentcolor;
}

.page-link {

  &.active,
  .active>& {
    z-index: 3;
    color: white;
    background-color: #3E465B !important;
    border-color: #3E465B !important;
    font-family: 'Inter Bold', sans-serif !important;
  }
}

.text-body-emphasis {
  font-family: 'Gilroy Extra Bold', sans-serif;
}

.btn-primary {
  font-family: 'Inter Bold', sans-serif !important;
}

.table {
  th {
    font-family: 'Gilroy Bold', sans-serif;
  }
}

.modal-base-title {
  font-family: 'Gilroy Bold', sans-serif;
  font-size: 20px;
}

.modal-label {
  font-family: 'Gilroy Bold', sans-serif;
  font-size: 14px;
  padding-left: 0px !important;
  padding-bottom: 2px;
}

.dropdown-control {
  padding: 12px 16px !important;
  font-family: 'Inter SemiBold', sans-serif !important;
  font-size: 5px;
  line-height: 18px;
  color: #525B75;
  width: 100% !important;
}

.form-control:focus {
  color: none !important;
  background-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.show-photo-btn {
  background: rgba(227, 230, 237, 1) !important;
}