.notification {
  &-dot {
    z-index: 1030;
    background-color: #E5780B;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 1.3rem;
    right: 8.2rem;

    &.is-notification {
      top: 4.1rem;
    }
  }

  &-toggler {
    &:hover {
      color: #353b4c;
      cursor: pointer;
    }
  }

  &-fixed {
    height: 3rem !important;
    min-height: 0rem !important;
    background-color: #FFE372 !important;
    border-bottom: none !important;
    font-size: 14px !important;

    &.warning {
      background-color: #F43C3C66 !important;
    }
  }

  &-item {
    font-family: "Inter Semibold" !important;
    font-size: 14px !important;
    background-color: #EFF2F6 !important;
    border: none !important;
    border-radius: 16px !important;
    background-color: var(--phoenix-body-bg) !important;

    .card-title {
      font-size: 14px !important;
    }

    &.new {
      background-color: #FFD8853D !important;

      &:hover {
        background-color: #FFD8853D !important;
      }
    }
  }

  &-badge {
    background-color: #FFD322 !important;
    border-radius: 6px !important;
    padding: 5px 8px !important;
    font-size: 14px !important;
    font-family: "Inter Bold" sans-serif !important;
  }

  &-time {
    color: #6E7891 !important;
  }
}

.notifications-sidebar {
  width: 43rem !important;
  transition: transform 0.4s ease-in-out !important;

  .offcanvas {
    &-title {
      font-family: "Gilroy Bold" !important;
    }

    &-body {
      overflow-y: auto;
    }

    &-header {
      .notifications-close {
        width: 8px !important;
        height: 15px !important;
        padding: 0px !important;
        color: #525B75 !important;

        &:hover {
          color: #353b4c;
          cursor: pointer;
        }
      }
    }
  }
}

.notifications-separator {
  font-family: "Gilroy Bold" !important;
  font-size: 10px;
  color: #8A94AD
}

.content.is-notification {
  padding-top: 9rem !important;
}

.navbar-nav.is-notification {
  padding-top: 2.5rem !important;
}

@media (min-width: 992px) {
  .notification-dot {
    right: 9.2rem;
  }
}